import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "./home/Home";
import { loginRequest } from "./authConfig";

const MainContent = () => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const handleRedirect = () => {
    instance
      .loginRedirect({
        ...loginRequest,
        prompt: "create",
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      <AuthenticatedTemplate>
        {activeAccount && (
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Navigate to="/home" />} />
              <Route path="/home" element={<Navigate to="/home/welcome" />} />
              <Route path="/home/*" element={<Home />} />
            </Routes>
          </BrowserRouter>
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <body className="h-screen bg-gray-100 flex items-center justify-center">
          <div className="bg-white p-8 rounded border w-96 text-center flex flex-col gap-y-4">
            <h1 className="text-2xl font-bold text-gray-800">Ben Audit Tool</h1>
            <p className="text-gray-600">Click below to sign in via SSO</p>
            <button
              className=" w-full rounded border border-gray-300 bg-gray-100 p-2 text-center text-sm font-medium text-black hover:bg-gray-200 transition-colors"
              onClick={handleRedirect}
            >
              Sign in
            </button>
          </div>
        </body>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default function App({ instance }) {
  return (
    <MsalProvider instance={instance}>
      <MainContent />
    </MsalProvider>
  );
}
