import Select from 'react-select';
import Icon from '../components/Icon';
import _ from 'lodash';
import Checkbox from '../components/Checkbox';
import {useMemo} from 'react';
import RadioGroup from '../components/RadioGroup';

const BaseField = ({field, options, onChange}) => {
    // noinspection JSUnresolvedReference
    return (
        <div className="grid grid-cols-3 items-center">
            <Select
                className="text-xs text-gray-800"
                placeholder="Select column..."
                value={field.column_map}
                isClearable={true}
                options={options.columns}
                onChange={(selected) => onChange(['column_map'], selected)}
            />
            <div className="flex justify-center">
                <Icon icon="arrow-right" />
            </div>
            <div className="rounded border border-gray-300 bg-white p-2 text-xs font-medium text-gray-600">{field.name}</div>
        </div>
    );
};

const SelectionField = ({field, options, onChange}) => {
    // noinspection JSUnresolvedReference
    return (
        <div className="flex flex-col gap-2">
            <BaseField
                field={field}
                options={options}
                onChange={onChange}
            />

            {field.column_map && (
                <div className="flex flex-col gap-2">
                    {field.selection_value_map ? (
                        <div className="flex flex-col gap-2">
                            <h3 className="text-xs font-medium text-gray-800">Value Mapping:</h3>

                            {Object.keys(field.selection_value_map).map((target, targetIndex) => (
                                <div key={targetIndex}>
                                    <div className="grid grid-cols-3 items-center">
                                        <Select
                                            isMulti
                                            className="text-xs text-gray-800"
                                            placeholder="Select values..."
                                            value={field.selection_value_map[target]}
                                            options={options.values[field.column_map.value]}
                                            onChange={(selected) => onChange(['selection_value_map', target], selected)}
                                        />
                                        <div className="flex justify-center">
                                            <Icon icon="arrow-right" />
                                        </div>
                                        <div className="rounded border border-gray-300 bg-white p-2 text-xs font-medium text-gray-600">{target}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className="text-xs text-gray-800">No value mapping targets available.</p>
                    )}
                </div>
            )}
        </div>
    );
};

const DateField = ({field, options, onChange}) => {
    // noinspection JSUnresolvedReference
    return (
        <div className="flex flex-col gap-2">
            <BaseField
                field={field}
                options={options}
                onChange={onChange}
            />

            <h3 className="text-xs font-medium text-gray-800">Year, Month & Day Ordering:</h3>
            <Select
                className="text-xs text-gray-800"
                placeholder="Specify order of date parts..."
                required={true}
                value={field.date_order || {value: 'Auto', label: 'Auto'}}
                options={options.date_order}
                onChange={(selected) => onChange(['date_order'], selected)}
            />
        </div>
    );
};

const NameField = ({field, options, onChange}) => {
    // noinspection JSUnresolvedReference
    return (
        <div className="flex flex-col gap-2">
            <RadioGroup
                value={field.name_type}
                options={[
                    {value: 'FIRST_AND_LAST', label: 'First & Last Name'},
                    {value: 'FULL', label: 'Full Name'},
                ]}
                onChange={(value) => onChange(['name_type'], value)}
            />

            {field.name_type === 'FIRST_AND_LAST' && (
                <div className="flex flex-col gap-2">
                    <Field
                        field={field.first_name_field}
                        options={options}
                        onChange={(path, value) => onChange(['first_name_field', ...path], value)}
                    />

                    <Field
                        field={field.last_name_field}
                        options={options}
                        onChange={(path, value) => onChange(['last_name_field', ...path], value)}
                    />
                </div>
            )}

            {field.name_type === 'FULL' && (
                <Field
                    field={field.full_name_field}
                    options={options}
                    onChange={(path, value) => onChange(['full_name_field', ...path], value)}
                />
            )}
        </div>
    );
};

const JSONField = ({field, options, json_path_options, selection_value_map_per_path, onChange}) => {
    // noinspection JSUnresolvedReference
    return (
        <div className="flex flex-col gap-y-2">
            <div className="grid grid-cols-2 gap-x-2">
                <div className="flex flex-col gap-y-2">
                    <h3 className="text-xs font-medium text-gray-800">JSON Path:</h3>
                    <Select
                        className="text-xs text-gray-800"
                        placeholder="Select path..."
                        value={field.json_path}
                        isClearable={true}
                        options={json_path_options}
                        onChange={(selected) => {
                            const updatedField = _.cloneDeep(field);

                            updatedField.json_path = selected;
                            // noinspection JSUnresolvedReference

                            updatedField.sub_field.name = selected ? `${field.name} (${selected.value})` : field.name;
                            // noinspection JSUnresolvedReference
                            updatedField.sub_field.selection_value_map = selected ? selection_value_map_per_path[selected.value] : null;
                            onChange([], updatedField);
                        }}
                    />
                </div>

                <div className="flex flex-col gap-y-2">
                    <h3 className="text-xs font-medium text-gray-800">Data Type:</h3>
                    <Select
                        className="text-xs text-gray-800"
                        placeholder="Select data type..."
                        value={field.sub_field_data_type}
                        options={options.data_type}
                        onChange={(selected) => {
                            const updatedField = _.cloneDeep(field);

                            updatedField.sub_field_data_type = selected;
                            // noinspection JSUnresolvedReference
                            updatedField.sub_field.data_type = selected.value;
                            onChange([], updatedField);
                        }}
                    />
                </div>
            </div>

            <Field
                field={field.sub_field}
                options={options}
                onChange={(path, value) => onChange(['sub_field', ...path], value)}
            />
        </div>
    );
};

const JSONFieldList = ({field, options, onChange}) => {
    // noinspection JSUnresolvedReference
    return (
        <div className="flex flex-col gap-y-2">
            {field.fields.map((subField, subFieldIndex) => (
                <div
                    key={subFieldIndex}
                    className="relative rounded bg-gray-100 p-4">
                    <button
                        type="button"
                        onClick={() =>
                            onChange(
                                ['fields'],
                                field.fields.filter((_, index) => index !== subFieldIndex)
                            )
                        }
                        className="absolute right-2 top-2 text-sm text-red-600 hover:text-red-700">
                        ✕
                    </button>

                    <JSONField
                        field={subField}
                        options={options}
                        json_path_options={field.json_path_options}
                        selection_value_map_per_path={field.selection_value_map_per_path}
                        onChange={(path, value) => onChange(['fields', subFieldIndex, ...path], value)}
                    />
                </div>
            ))}

            {field.fields.length >= 5 ? (
                <p className="text-center text-xs text-gray-800">Maximum of 5 fields can be added.</p>
            ) : (
                <button
                    className="text-xs text-gray-700 underline transition-colors hover:text-gray-800"
                    onClick={() => onChange(['fields'], [..._.cloneDeep(field.fields), _.cloneDeep(field.field_template)])}>
                    Add Field
                </button>
            )}
        </div>
    );
};

const fieldMap = {
    SELECTION: SelectionField,
    DATE: DateField,
    NAME: NameField,
    JSON: JSONField,
    JSON_LIST: JSONFieldList,
};

const Field = ({field, options, onChange}) => {
    const FieldBody = useMemo(() => fieldMap[field.data_type] || BaseField, [field.data_type]);
    return (
        <FieldBody
            field={field}
            options={options}
            onChange={onChange}
        />
    );
};

export default function Entry({field, options, onChange}) {
    return (
        <div className="flex flex-col gap-y-2">
            <Checkbox
                label={field.name}
                required={field.required}
                value={field.checked}
                description={field.description}
                onChange={(checked) => onChange(['checked'], checked)}
            />

            {(field.required || field.checked) && (
                <Field
                    field={field}
                    options={options}
                    onChange={onChange}
                />
            )}
        </div>
    );
}
