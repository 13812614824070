// icon:reload-circle-outline | Ionicons https://ionicons.com/ | Ionic Framework
// icon:delete-bin-5-line | Remix Icon https://remixicon.com/ | Remix Design
import React from "react";

function IconCheck({ size }) {
  return (
    <svg className={size} viewBox="0 0 512 512" fill="currentColor">
      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm48.19 121.42l24.1 21.06-73.61 84.1-24.1-23.06zM191.93 342.63L121.37 272 144 249.37 214.57 320zm65 .79L185.55 272l22.64-22.62 47.16 47.21 111.13-127.17 24.1 21.06z" />
    </svg>
  );
}

function IconX({ size }) {
  return (
    <svg className={size} viewBox="0 0 16 16" fill="currentColor">
      <path
        fillRule="evenodd"
        d="M2.343 13.657A8 8 0 1113.657 2.343 8 8 0 012.343 13.657zM6.03 4.97a.75.75 0 00-1.06 1.06L6.94 8 4.97 9.97a.75.75 0 101.06 1.06L8 9.06l1.97 1.97a.75.75 0 101.06-1.06L9.06 8l1.97-1.97a.75.75 0 10-1.06-1.06L8 6.94 6.03 4.97z"
      />
    </svg>
  );
}

function IconClock({ size }) {
  return (
    <svg className={size} viewBox="0 0 24 24" fill="currentColor">
      <path d="M12 20c4.4 0 8-3.6 8-8s-3.6-8-8-8-8 3.6-8 8 3.6 8 8 8m0-18c5.5 0 10 4.5 10 10s-4.5 10-10 10S2 17.5 2 12 6.5 2 12 2m5 11.9l-.7 1.3-5.3-2.9V7h1.5v4.4l4.5 2.5z" />
    </svg>
  );
}

function IconError({ size }) {
  return (
    <svg className={size} viewBox="0 0 1024 1024" fill="currentColor">
      <path d="M955.7 856l-416-720c-6.2-10.7-16.9-16-27.7-16s-21.6 5.3-27.7 16l-416 720C56 877.4 71.4 904 96 904h832c24.6 0 40-26.6 27.7-48zM480 416c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v184c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V416zm32 352a48.01 48.01 0 010-96 48.01 48.01 0 010 96z" />
    </svg>
  );
}

function IconAudit({ size }) {
  return (
    <svg className={size} viewBox="0 0 1024 1024" fill="currentColor">
      <path d="M296 250c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H296zm184 144H296c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm-48 458H208V148h560v320c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V108c0-17.7-14.3-32-32-32H168c-17.7 0-32 14.3-32 32v784c0 17.7 14.3 32 32 32h264c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm440-88H728v-36.6c46.3-13.8 80-56.6 80-107.4 0-61.9-50.1-112-112-112s-112 50.1-112 112c0 50.7 33.7 93.6 80 107.4V764H520c-8.8 0-16 7.2-16 16v152c0 8.8 7.2 16 16 16h352c8.8 0 16-7.2 16-16V780c0-8.8-7.2-16-16-16zM646 620c0-27.6 22.4-50 50-50s50 22.4 50 50-22.4 50-50 50-50-22.4-50-50zm180 266H566v-60h260v60z" />
    </svg>
  );
}

function IconDownload({ size }) {
  return (
    <svg className={size} viewBox="0 0 512 512" fill="currentColor">
      <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32v242.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64h384c35.3 0 64-28.7 64-64v-32c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 104c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24z" />
    </svg>
  );
}

function IconUpload({ size }) {
  return (
    <svg className={size} viewBox="0 0 24 24" fill="currentColor">
      <path d="M9 16v-6H5l7-7 7 7h-4v6H9m-4 4v-2h14v2H5z" />
    </svg>
  );
}

function IconArrowRight({ size }) {
  return (
    <svg className={size} fill="currentColor" viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        d="M1 8a.5.5 0 01.5-.5h11.793l-3.147-3.146a.5.5 0 01.708-.708l4 4a.5.5 0 010 .708l-4 4a.5.5 0 01-.708-.708L13.293 8.5H1.5A.5.5 0 011 8z"
      />
    </svg>
  );
}

function IconCaretLeft({ size }) {
  return (
    <svg className={size} fill="none" viewBox="0 0 15 15">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.818 4.182a.45.45 0 010 .636L6.136 7.5l2.682 2.682a.45.45 0 11-.636.636l-3-3a.45.45 0 010-.636l3-3a.45.45 0 01.636 0z"
        clipRule="evenodd"
      />
    </svg>
  );
}

function IconDoubleCaretLeft({ size }) {
  return (
    <svg className={size} fill="none" viewBox="0 0 15 15">
      <path
        stroke="currentColor"
        strokeLinecap="square"
        d="M6.5 9.5l-2-2 2-2m3 4l-2-2 2-2"
      />
    </svg>
  );
}

function IconCaretRight({ size }) {
  return (
    <svg className={size} fill="none" viewBox="0 0 15 15">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.182 4.182a.45.45 0 01.636 0l3 3a.45.45 0 010 .636l-3 3a.45.45 0 11-.636-.636L8.864 7.5 6.182 4.818a.45.45 0 010-.636z"
        clipRule="evenodd"
      />
    </svg>
  );
}

function IconDoubleCaretRight({ size }) {
  return (
    <svg className={size} fill="none" viewBox="0 0 15 15">
      <path
        stroke="currentColor"
        strokeLinecap="square"
        d="M8.5 9.5l2-2-2-2m-3 4l2-2-2-2"
      />
    </svg>
  );
}

function IconNext({ size }) {
  return (
    <svg className={size} viewBox="0 0 24 24" fill="currentColor">
      <path d="M11.293 17.293l1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z" />
    </svg>
  );
}

function IconTool({ size }) {
  return (
    <svg
      className={size}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      viewBox="0 0 24 24"
    >
      <path d="M14.7 6.3a1 1 0 000 1.4l1.6 1.6a1 1 0 001.4 0l3.77-3.77a6 6 0 01-7.94 7.94l-6.91 6.91a2.12 2.12 0 01-3-3l6.91-6.91a6 6 0 017.94-7.94l-3.76 3.76z" />
    </svg>
  );
}

function IconInfo({ size }) {
  return (
    <svg className={size} fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M11 10.98a1 1 0 112 0v6a1 1 0 11-2 0v-6zM12 6.051a1 1 0 100 2 1 1 0 000-2z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zM4 12a8 8 0 1016 0 8 8 0 00-16 0z"
        clipRule="evenodd"
      />
    </svg>
  );
}

function IconAdd({ size }) {
  return (
    <svg className={size} viewBox="0 0 512 512" fill="currentColor">
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={32}
        d="M256 112v288M400 256H112"
      />
    </svg>
  );
}

function IconDelete({ size }) {
  return (
    <svg className={size} viewBox="0 0 24 24" fill="currentColor">
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M4 8h16v13a1 1 0 01-1 1H5a1 1 0 01-1-1V8zm2 2v10h12V10H6zm3 2h2v6H9v-6zm4 0h2v6h-2v-6zM7 5V3a1 1 0 011-1h8a1 1 0 011 1v2h5v2H2V5h5zm2-1v1h6V4H9z" />
    </svg>
  );
}

function IconSignOut({ size }) {
  return (
    <svg className={size} viewBox="0 0 16 16" fill="currentColor">
      <path
        fillRule="evenodd"
        d="M2 2.75C2 1.784 2.784 1 3.75 1h2.5a.75.75 0 010 1.5h-2.5a.25.25 0 00-.25.25v10.5c0 .138.112.25.25.25h2.5a.75.75 0 010 1.5h-2.5A1.75 1.75 0 012 13.25V2.75zm10.44 4.5H6.75a.75.75 0 000 1.5h5.69l-1.97 1.97a.75.75 0 101.06 1.06l3.25-3.25a.75.75 0 000-1.06l-3.25-3.25a.75.75 0 10-1.06 1.06l1.97 1.97z"
      />
    </svg>
  );
}

export default function Icon({ icon, size }) {
  const sizeMap = {
    xs: "h-3 w-3",
    sm: "h-4 w-4",
    md: "h-5 w-5",
    lg: "h-6 w-6",
    xl: "h-7 w-7",
  };
  const sizeClass = sizeMap[size] || sizeMap.md;

  switch (icon) {
    case "check":
      return <IconCheck size={sizeClass} />;
    case "x":
      return <IconX size={sizeClass} />;
    case "clock":
      return <IconClock size={sizeClass} />;
    case "audit":
      return <IconAudit size={sizeClass} />;
    case "upload":
      return <IconUpload size={sizeClass} />;
    case "download":
      return <IconDownload size={sizeClass} />;
    case "arrow-right":
      return <IconArrowRight size={sizeClass} />;
    case "caret-left":
      return <IconCaretLeft size={sizeClass} />;
    case "double-caret-left":
      return <IconDoubleCaretLeft size={sizeClass} />;
    case "caret-right":
      return <IconCaretRight size={sizeClass} />;
    case "double-caret-right":
      return <IconDoubleCaretRight size={sizeClass} />;
    case "next":
      return <IconNext size={sizeClass} />;
    case "tool":
      return <IconTool size={sizeClass} />;
    case "info":
      return <IconInfo size={sizeClass} />;
    case "add":
      return <IconAdd size={sizeClass} />;
    case "delete":
      return <IconDelete size={sizeClass} />;
    case "sign-out":
      return <IconSignOut size={sizeClass} />;
    case "error":
      return <IconError size={sizeClass} />;
    default:
      return <IconError size={sizeClass} />;
  }
}
