import React, {useId} from 'react';

export default function RadioGroup({options, value, onChange}) {
    const uniqueId = useId(); // Generate a unique ID for the group

    return (
        <div className="flex w-full justify-center gap-x-4 rounded bg-gray-100 p-4">
            {options.map((option, index) => (
                <div
                    className="flex items-center gap-x-2"
                    key={index}>
                    <input
                        key={option.value}
                        type="radio"
                        id={`${uniqueId}-${option.value}`}
                        name={uniqueId}
                        value={option.value}
                        checked={value === option.value}
                        onChange={(e) => onChange(e.target.value)}
                    />

                    <label
                        htmlFor={`${uniqueId}-${option.value}`}
                        className="text-xs font-medium text-gray-800">
                        {option.label}
                    </label>
                </div>
            ))}
        </div>
    );
}
