import PropTypes from "prop-types";
import Entry from "./Field";
import RadioGroup from "../components/RadioGroup";

// Main Component
export default function AuditConfig({ data, onChange }) {
  // noinspection JSUnresolvedReference
  return (
    <div className="flex flex-col gap-y-4">
      <RadioGroup
        options={data.options.audit_type}
        value={data.audit_type}
        onChange={(selected) => onChange(["audit_type"], selected)}
      />

      <div className="flex flex-col gap-y-1 rounded bg-gray-100 p-4 text-sm justify-center">
        <p className="text-gray-500 text-center">
          Please refer to the documentation at the following link to understand
          how to interpret the types above & what each expects:
        </p>
        <a
          href="https://www.notion.so/thanksben/Auditing-Audit-Tool-101-15bc5fff8f8d805f8d7ffa24caa21431?pvs=4#15bc5fff8f8d806089ecfc1b3a2dd6c5"
          target="_blank"
          rel="noreferrer"
          className="text-center text-purple-600 hover:underline font-medium transition-colors hover:text-purple-700 active:text-purple-800"
        >
          Auditing & Audit Tool 101 - Audit Types
        </a>
      </div>

      {data.sections.map((section, sectionIndex) => (
        <div
          key={sectionIndex}
          className={!section.applies_to.includes(data.audit_type) && "hidden"}
        >
          <div className="mb-1 flex items-center gap-x-2">
            <h3 className="text-sm font-medium uppercase text-gray-800">
              {section.name}
            </h3>
            <div className="h-px flex-grow bg-gray-300"></div>
          </div>
          <p className="mb-4 text-sm text-gray-500">{section.description}</p>

          {section.fields.map((field, fieldIndex) => (
            <div
              className="mb-2 rounded border border-gray-300 p-3"
              key={fieldIndex}
            >
              <Entry
                field={field}
                options={data.options}
                onChange={(path, value) =>
                  onChange(
                    ["sections", sectionIndex, "fields", fieldIndex, ...path],
                    value,
                  )
                }
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

AuditConfig.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
