export default function About() {
  return (
    <div className="flex flex-col gap-y-4">
      <h1 className="text-xl font-medium text-gray-800">About</h1>
      <div className="flex flex-col gap-y-4 w-full rounded border border-gray-300 bg-white p-8">
        <p className="text-gray-500">
          The Audit Tool is an attempt by the Data Team at Ben to work towards a
          sustainable method to perform audits between provider membership lists
          & Ben Enrollment data.
        </p>
        <p className="text-gray-500">
          This application is still meant to be a proof-of-concept and is a way
          for us to discover and experiment with how we can automate or enable a
          faster & more accurate audit process.
        </p>
        <p className="text-gray-500">
          With the next iterations, we will aim to integrate some of the ideas
          from the tool into Ben's internal systems.
        </p>

        <div className="flex flex-col gap-y-1 rounded bg-gray-100 p-4">
          <p className="text-gray-500">
            For more information about auditing & usage instructions for the
            tool, please refer to the documentation at the following link:
          </p>
          <a
            href="https://www.notion.so/thanksben/Auditing-Audit-Tool-101-15bc5fff8f8d805f8d7ffa24caa21431"
            target="_blank"
            rel="noreferrer"
            className="text-purple-600 hover:underline font-medium transition-colors hover:text-purple-700 active:text-purple-800"
          >
            Auditing & Audit Tool 101
          </a>
        </div>
      </div>

      <div className="flex flex-col gap-y-4 w-full rounded border border-gray-300 bg-white p-8">
        <h1 className="text-lg font-semibold">First Contacts</h1>
        <div className="grid grid-cols-3 gap-4">
          <div className="flex flex-col gap-y-1 rounded bg-gray-100 p-4">
            <h2 className="text-sm font-semibold">Vinh Pham Van</h2>
            <p className="text-xs text-gray-500">vinh.phamvan@thanksben.com</p>
          </div>
          <div className="flex flex-col gap-y-1 rounded bg-gray-100 p-4">
            <h2 className="text-sm font-semibold">John Walsh</h2>
            <p className="text-xs text-gray-500">john@thanksben.com</p>
          </div>
          <div className="flex flex-col gap-y-1 rounded bg-gray-100 p-4">
            <h2 className="text-sm font-semibold">Gareth Denny</h2>
            <p className="text-xs text-gray-500">gareth@thanksben.com</p>
          </div>
        </div>
      </div>
    </div>
  );
}
