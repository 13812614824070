import React, { useRef } from "react";
import { Link, Route, Routes } from "react-router-dom";
import Icon from "../components/Icon";
import logo from "../assets/logo.png";
import AuditNew from "../audit/AuditNew";
import { useMsal } from "@azure/msal-react";
import About from "./About";
import Welcome from "./Welcome";

export default function Home() {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const scrollRef = useRef(null);

  const handleSignOut = async () => {
    await instance.logoutRedirect({
      onRedirectNavigate: (url) => {
        // Return false if you would like to stop navigation after local logout
        return false;
      },
      account: activeAccount,
    });
  };

  // noinspection JSValidateTypes
  return (
    <div className="flex h-screen overflow-hidden">
      {/* SideBar */}
      <aside className="flex flex-col gap-y-4 w-64 bg-black p-8">
        {/* Ben Logo */}
        <img src={logo} alt="Ben Logo" className="h-auto w-1/2" />

        {/* Title */}
        <h2 className="font-bold text-white">Audit Tool</h2>

        {/* Divider */}
        <div className="border-b-2 border-white" />

        {/* User */}
        <h2 className="text-white">{activeAccount.name}</h2>
        <button
          className="w-full flex items-center gap-x-3 p-3 text-center text-sm font-medium text-black hover:bg-gray-100 bg-white rounded transition-colors"
          onClick={handleSignOut}
        >
          <Icon icon="sign-out" />
          <p>Sign Out</p>
        </button>

        {/* Divider */}
        <div className="border-b-2 border-white" />

        {/* Navigation */}
        <ul>
          <li>
            <Link
              to="/home/audit/new"
              className="flex items-center gap-x-4 p-2 text-sm font-semibold text-white transition-colors hover:text-gray-200 active:text-gray-300"
            >
              <Icon icon="add" />
              <span>New Audit</span>
            </Link>
          </li>
          <li>
            <Link
              to="/home/about"
              className="flex items-center gap-x-4 p-2 text-sm font-semibold text-white transition-colors hover:text-gray-200 active:text-gray-300"
            >
              <Icon icon="info" />
              <span>About</span>
            </Link>
          </li>
        </ul>
      </aside>

      {/* Content */}
      <main className="flex-grow bg-gray-100">
        <div className="h-full overflow-y-scroll" ref={scrollRef}>
          <div className="mx-auto max-w-6xl p-8">
            <Routes>
              <Route
                path="welcome"
                element={<Welcome account={activeAccount} />}
              />
              <Route
                path="audit/new"
                element={<AuditNew scrollRef={scrollRef} />}
              />
              <Route path="about" element={<About />} />
            </Routes>
          </div>
        </div>
      </main>
    </div>
  );
}
