import React from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../components/Icon";

export default function Welcome({ account }) {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-y-4">
      <h1 className="text-xl font-medium text-gray-800">
        Welcome to the Audit Tool, {account.name}!
      </h1>
      <div className="flex flex-col gap-y-4 w-full rounded border border-gray-300 bg-white p-8">
        <p className="text-gray-500">
          The Audit tool has been built to streamline the process comparing
          Provider Membership Lists and Ben Enrollment Data by exposing a
          configurable column / value mapping from the Membership list file to
          their respective data points in the Ben system.
        </p>
        <p className="text-gray-500">
          The user will be able to select which fields could be used for
          matching records between the two datasets, and which fields should be
          audited for discrepancies. The tool will then flag:
        </p>
        <ul className="list-disc pl-4 text-gray-500">
          <li>Any records that matched with any duplicates noted.</li>
          <li>
            Any records on either the Membership List or on Ben's side that did
            not match up based on the selected matching fields.
          </li>
          <li>Any discrepancies for the fields selected to be audited.</li>
          <li>
            If dependants exist in a parsable format, the tool will also flag if
            it found employees with a mismatching list of dependants.
          </li>
        </ul>

        <p className="text-gray-500">
          The audit results will also be available for download in an XSLX
          format for further analysis.
        </p>
      </div>

      <div className="flex flex-col gap-y-4 w-full rounded border border-gray-300 bg-white p-8">
        <div className="flex flex-col gap-y-4 rounded bg-gray-100 p-4">
          <p className="text-gray-600 font-medium">
            In order to perform an audit, please have the following ready:
          </p>
          <ul className="list-disc pl-4 text-gray-500">
            <li>The list of Benefits / Benefit IDs to be audited.</li>
            <li>
              The Provider Membership List in a valid CSV (Comma Separated
              Values) format. Valid CSV files should have:
              <ul className="list-disc pl-4 text-gray-500">
                <li>Headers in the first row</li>
                <li>Rows with the same number of columns as the headers</li>
                <li>Every row should have the same column order</li>
                <li>No rows should be fully empty</li>
              </ul>
            </li>
            <li>
              An idea of: The fields can we used for matching (unique IDs) &
              data points to audit (e.g. Prices, Member Start Dates, etc.)
            </li>
          </ul>
        </div>

        <p className="font-medium text-gray-800">
          Please follow the documentation below to for full overview of the
          concepts & for the usage instructions of the tool.
        </p>

        <div className="grid grid-cols-2 gap-x-4 ">
          <a
            className="flex items-center justify-center gap-x-2 rounded border border-transparent bg-purple-600 px-4 py-2 text-center text-sm font-medium text-white transition-colors hover:bg-purple-700 focus:outline-none active:bg-purple-800"
            target="_blank"
            rel="noreferrer"
            href="https://www.notion.so/thanksben/Auditing-Audit-Tool-101-15bc5fff8f8d805f8d7ffa24caa21431"
          >
            <Icon icon="audit" />
            <p>Documentation</p>
          </a>

          <button
            className="flex items-center justify-center gap-x-2 rounded border border-transparent bg-teal-600 px-4 py-2 text-center text-sm font-medium text-white transition-colors hover:bg-teal-700 focus:outline-none active:bg-teal-800"
            onClick={() => navigate("/home/audit/new")}
          >
            <Icon icon="add" />
            <p>Click to start a new audit</p>
          </button>
        </div>
      </div>
    </div>
  );
}
