import { useEffect, useState } from "react";
import { API_URL } from "../constants";
import Audit from "./Audit";
import useAuthenticatedAxios from "../utils/authenticated-axios";

export default function AuditNew({ scrollRef }) {
  const authenticatedAxios = useAuthenticatedAxios();

  const [auditId, setAuditId] = useState(null);
  const [createAuditError, setCreateAuditError] = useState(null);

  useEffect(() => {
    const createAudit = async () => {
      try {
        const createResponse = await authenticatedAxios.post(
          `${API_URL}/api/audit/create`,
        );

        // noinspection JSUnresolvedReference
        const auditId = createResponse.data.audit_id;
        setAuditId(auditId);
      } catch (error) {
        setCreateAuditError("Error creating session");
      }
    };

    // noinspection JSIgnoredPromiseFromCall
    createAudit();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (auditId) {
    return (
      <Audit
        auditId={auditId}
        authenticatedAxios={authenticatedAxios}
        scrollRef={scrollRef}
      />
    );
  } else {
    return (
      <div className="flex flex-col gap-y-4">
        <h1 className="text-xl font-medium text-gray-800">
          Setting up New Audit...
        </h1>
        <div className="w-full flex flex-col gap-y-4 rounded border border-gray-300 bg-white p-8">
          <p className="text-gray-500">
            Please wait while a new audit session is created...
          </p>

          {/* Audit Creation Error */}
          {createAuditError && (
            <div className="rounded border border-red-300 bg-red-100 p-4 text-sm text-red-500">
              {createAuditError}
            </div>
          )}
        </div>
      </div>
    );
  }
}
