import axios from "axios";
import { useMsal } from "@azure/msal-react";

/**
 * Axios instance that will automatically include the
 * active account's idToken in the Authorization header.
 *
 * @returns {axios.AxiosInstance}
 */
const useAuthenticatedAxios = () => {
  const { instance } = useMsal();
  const account = instance.getActiveAccount();

  const axiosInstance = axios.create();
  if (account) {
    axiosInstance.defaults.headers.common["Authorization"] =
      `Bearer ${account.idToken}`;
  }
  return axiosInstance;
};

export default useAuthenticatedAxios;
