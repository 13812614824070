import { useState } from "react";
import { PaginatedTable } from "./Table";
import PropTypes from "prop-types";
import Icon from "./Icon";

export default function Result({ title, icon, count, data }) {
  const [isTableVisible, setTableVisible] = useState(false);

  const toggleView = () => {
    setTableVisible((prev) => !prev);
  };

  return (
    <div className="flex flex-col gap-y-4 rounded border border-gray-300 bg-white px-8 py-6">
      <div className="flex items-center justify-between gap-y-2">
        <div className="flex items-center gap-x-2">
          <Icon icon={icon} size="sm" />
          <h2 className="font-semibold">{title}:</h2>
          <p className="text-gray-500">{count}</p>
        </div>
        {data && data.rows.length > 0 && (
          <button
            className="text-sm font-medium text-purple-600 underline hover:text-purple-700 active:text-purple-800"
            onClick={toggleView}
          >
            {isTableVisible ? "Hide" : "View"}
          </button>
        )}
      </div>

      {data && data.rows.length > 0 && isTableVisible && (
        <div className="pb-2">
          <PaginatedTable
            headers={data.headers}
            rows={data.rows}
            rowsPerPage={15}
          />
        </div>
      )}
    </div>
  );
}

Result.propTypes = {
  data: PropTypes.object.isRequired,
};

Result.defaultProps = {
  data: {},
};
